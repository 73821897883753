
import {Vue, Component, Prop, Watch, Mixins} from "vue-property-decorator";
import {GenelRaporlamaEntity} from "@/entity/Raporlama/GenelRaporlamaEntity";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";

@Component({

})
export default class GenelIstatistikGrafikInfo extends Mixins(ObjectInputMixin) {

  @Prop() series !: Array<number|string>
  @Prop() yuzde !: number |string


  options = {
    series: this.series,
    labels: 'Açılmamış Dosya Sayısı',
    chart: {
      offsetX: -14,
      height: 140,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 15,
          size: "40%"
        },
        track :{
          background: '#D9D9D9',
        },

        dataLabels: {
          show: false,
        }
      }
    },
    stroke: {
      lineCap: "round",
    },
    colors: ['#5FB3F6']

  };


   }
