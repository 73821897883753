
import {Component, Mixins, Prop, Vue, Watch} from 'vue-property-decorator';
import {TahsilatlarTableEntity} from "@/entity/Raporlama/TahsilatlarTableEntity";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";

@Component
export default class TahsilatlarDataTable extends Mixins(ObjectInputMixin) {
  @Prop() muvekkilId!:number| null;
  @Prop() kurumId!:number| null;

  @Prop() baslangicTarihi!: string
  @Prop() bitisTarihi!: string

  search: string = '';
  isLoading = true;
  yilSecim: string[] | never[] = [];


  get sutunlarItems() {
    return [
      { text: 'Tümünü Seç', value: 'selectAll' },
      ...this.headers,
    ];
  }

  @Watch('sutunlar' )
  onSutunlarChange() {
    // Handle Select All option
    if (this.sutunlar.includes('selectAll')) {
      if (this.sutunlar.length === this.headers.length + 1) {
        this.sutunlar = [];
      } else {
        this.sutunlar = this.headers.map(header => header.value);
      }
    }
  }
  headers = [
    {
      text: 'Müvekkil Adı',
      align: 'start',
      sortable: false,
      value: 'muvekkil_adi',
      class:'header-style'
    },
    { text: 'Toplam Tahsilat', value: 'genel_tahsilat_toplami', class:'header-style' },
    { text: 'Açılmamış Dosya Tahsilatı', value: 'acilmamis_takip_tahsilat_tutar_toplam', class:'header-style' },
    { text: 'Derdest Dosya Tahsilatı', value: 'derdest_takip_tahsilat_tutar_toplam', class:'header-style' },
    { text: 'Hitam Dosya Tahsilatı', value: 'hitam_takip_tahsilat_tutar_toplam', class:'header-style' },
  ];
  sutunlar: string[] = this.headers.map(header => header.value);


  items: Array<TahsilatlarTableEntity> = [];

  calculateTotal(fieldName: string): number {
    return this.items.reduce((total, item) => total + Number(item[fieldName]), 0);
  }
  async fetchData() {
    try {
      const response:any = await this.$http.post('/api/v1/tahsilat-analiz-tablosu/', {
          "basTar": this.baslangicTarihi,
          "bitTar": this.bitisTarihi,
          "muvekkil_id" :this.muvekkilId,
          "kurum_id" :this.kurumId,


      });
      this.items = response.map((tahsilatItems: any) => {
      return tahsilatItems;
      });
    } catch (error) {
      console.error('hata:', error);
    }
  }
  @Watch('muvekkilId', )
  onPropsChange() {
    this.fetchData();
  }

  /*yilModelDuzenle(yilSecim: string) {
    const simdi = new Date();
    if (yilSecim === 'Bu Yıl') {
      const baslangicTarihi = new Date(simdi.getFullYear(), 0, 1);
      this.yilSecim = [
        baslangicTarihi.toLocaleDateString('tr-TR'),
        simdi.toLocaleDateString('tr-TR'),
      ];
    } else if (yilSecim === 'Geçen Yıl') {
      const gecenYil = simdi.getFullYear() - 1;
      const baslangicTarihi = new Date();
      baslangicTarihi.setFullYear(gecenYil, 0, 1);
      const bitisTarihi = new Date();
      bitisTarihi.setFullYear(gecenYil, 11, 31);
      this.yilSecim = [
        baslangicTarihi.toLocaleDateString('tr-TR'),
        bitisTarihi.toLocaleDateString('tr-TR'),
      ];
    }
  }


  @Watch('yilSecim')
  onYilSecimChange(yeni: string){
    console.log('yil degistirildi', this.yilSecim)
    this.yilModelDuzenle(yeni);
  }*/
  get seciliHeader() {
    return this.headers.filter((header) => this.sutunlar.includes(header.value));
  }
}
