
import {Vue, Component, Prop, Watch, Mixins} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({

})
export default class TahsilatlarHaftaChart extends Mixins(SimpleInputMixin){
  @Prop() selectedMuvId: number | null | undefined;
  @Prop() selectedKurumId: number | null | undefined;
  @Prop()series!:Array<any>

  tahsilatOraniTitleChart = ["Toplam Tahsilat", "Tahsilat Oranı", "Derdest Kısmi Toplam", "Hitam Tahsilat"]

  options = {
    series: this.series,
    chart: {
      type: 'bar',
    },
    colors: ["#5FB3F6", "#BFC9D2"],
    plotOptions: {
      bar: {
        borderRadius: 4,
        columnWidth: '15%',
        distributed: true,
      }
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: false
    },
    xaxis: {
      categories: [
        'Pazartesi',
        'Salı',
        'Çarşamba',
        'Perşembe',
        'Cuma',
        'Cumartesi',
        'Pazar',
      ],
      labels: {
        style: {
          show: true,
          fontSize: '11px',
          color:'#A4A4A4',
        }
      }
    }
  };

}
