
import GenelIstatistikPerformansGrafik
  from "@/components/infos/raporlama-ofispro/helper-comps/GenelIstatistikPerformansGrafik";
import MuvekkilTableInfo from "@/components/infos/raporlama-ofispro/MuvekkilTableInfo";
import UstBasliklar from "@/components/infos/raporlama-ofispro/helper-comps/UstBasliklar";
import TahsilatlarChart from "@/components/infos/raporlama-ofispro/helper-comps/TahsilatlarChart";
import TahsilatlarDataTable from "@/components/infos/raporlama-ofispro/helper-comps/TahsilatlarDataTable";
import Dates from "@/components/inputs/Dates";
import {Component, Mixins, Prop, Vue, Watch} from "vue-property-decorator";
import GenelIstatistikGrafikInfo from "@/components/infos/raporlama-ofispro/helper-comps/GenelIstatistikGrafikInfo";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import OzelAralikDatePicker from "@/components/infos/raporlama-ofispro/helper-comps/OzelAralikDatePicker.vue";
import TahsilatlarAyChart from "@/components/infos/raporlama-ofispro/helper-comps/TahsilatlarAyChart.vue";
import TahsilatlarHaftaChart from "@/components/infos/raporlama-ofispro/helper-comps/TahsilatlarHaftaChart.vue";
import MuvekkilSecim from "@/components/infos/raporlama-ofispro/helper-comps/MuvekkilSecim.vue";

@Component({
  components: {
    MuvekkilSecim,
    TahsilatlarHaftaChart,
    TahsilatlarAyChart,
    OzelAralikDatePicker,
    UstBasliklar,
    MuvekkilTableInfo,
    GenelIstatistikPerformansGrafik,
    TahsilatlarChart,
    TahsilatlarDataTable,
    Dates,
    GenelIstatistikGrafikInfo
  }
})
export default class TahsilatlarView extends Mixins(ObjectInputMixin) {

  selectedItem = null
  selectedItemTahsilatOrani = null
  baslangicTarihi : string = ''
  bitisTarihi : string = ''
  loading: boolean = false;
  tahsilatlarYil: boolean = true
  tahsilatlarAy: boolean = false
  tahsilatlarHafta: boolean = false
  tahsilatOraniTitle = ["Genel Toplam", "Asıl Alacak", "Takip Toplamı"]
  selectedOption = null
  cards: {
    id: number;
    icon: string;
    title: string;
    number: string | number;
    subtext: string | null;
    backgroundColor: string;
  }[] = [];
  items = ["Bu Yıl", "Bu Ay", "Bu Hafta"]
  selectedMuvId: Array<number | null> = [null];
  selectedKurumId: Array<number | null> = [null];
  tahsilatlarGrafikData: any = []
  tahsilatlarGrafikAyData: any = []
  tahsilatlarGrafikHaftaData: any = []
  type: string = 'Genel Toplama oranla'

  allData :any = []



  handleSelectedOption(selectedOption){
    this.selectedOption = selectedOption
    if (this.selectedItem === "Bu Yıl"){
      if (selectedOption ==='Toplam Tahsilat'){
        const seriesData = Array(12).fill(0);

        for (const tahsilat of this.allData.tarihli_toplamlar.toplam) {
          const monthIndex = new Date(tahsilat.tarih).getMonth();
          seriesData[monthIndex] += Number(tahsilat.toplam_tutar);
        }

        this.tahsilatlarGrafikData = [
          {
            name: 'Toplam Tahsilat',
            data: seriesData,
          },
        ]
      }else if(selectedOption ==='Tahsilat Oranı'){
        /*const seriesData = Array(12).fill(0);

        for (const tahsilat of this.allData.tarihli_toplamlar.kapali) {
          const monthIndex = new Date(tahsilat.tarih).getMonth();
          seriesData[monthIndex] += Number(tahsilat.toplam_tutar);
        }


        this.tahsilatlarGrafikData = [
          {
            name: 'Hitam Tahsilat',
            data: seriesData,
          },
        ]*/
      }else if(selectedOption ==='Hitam Tahsilat'){
        const seriesData = Array(12).fill(0);

        for (const tahsilat of this.allData.tarihli_toplamlar.kapali) {
          const monthIndex = new Date(tahsilat.tarih).getMonth();
          seriesData[monthIndex] += Number(tahsilat.toplam_tutar);
        }


        this.tahsilatlarGrafikData = [
          {
            name: 'Hitam Tahsilat',
            data: seriesData,
          },
        ]
      }else if(selectedOption ==='Derdest Kısmi Toplam'){
        const seriesData = Array(12).fill(0);

        for (const tahsilat of this.allData.tarihli_toplamlar.acik) {
          const monthIndex = new Date(tahsilat.tarih).getMonth();
          seriesData[monthIndex] += Number(tahsilat.toplam_tutar);
        }


        this.tahsilatlarGrafikData = [
          {
            name: 'Hitam Tahsilat',
            data: seriesData,
          },
        ]
      }
    }else if(this.selectedItem === "Bu Ay"){
      if (this.selectedOption === 'Toplam Tahsilat'){
        const seriesData = Array(5).fill(0);

        for (const tahsilat of this.allData.tarihli_toplamlar.toplam) {
          const tarih = new Date(tahsilat.tarih);

          // Bu ayın kaçıncı haftasına denk geliyor kontrolü
          const weekNumber = Math.ceil(tarih.getDate() / 7);

          // Veriyi ilgili haftaya ekle
          seriesData[weekNumber - 1] += Number(tahsilat.toplam_tutar);
          console.log('weeknumber --->', weekNumber);
        }

        this.tahsilatlarGrafikAyData = [
          {
            name:'Toplam Tahsilat',
            data: seriesData,
          },
        ];
      }else if(this.selectedOption === 'Tahsilat Oranı'){
        /*const seriesData = Array(5).fill(0);

        for (const tahsilat of this.allData.tarihli_toplamlar.toplam) {
          const tarih = new Date(tahsilat.tarih);

          // Bu ayın kaçıncı haftasına denk geliyor kontrolü
          const weekNumber = Math.ceil(tarih.getDate() / 7);

          // Veriyi ilgili haftaya ekle
          seriesData[weekNumber - 1] += Number(tahsilat.toplam_tutar);
          console.log('weeknumber --->', weekNumber);
        }

        this.tahsilatlarGrafikAyData = [
          {
            name:'Toplam Tahsilat',
            data: seriesData,
          },
        ];*/
      }else if (this.selectedOption === 'Derdest Kısmi Toplam'){
        const seriesData = Array(5).fill(0);

        for (const tahsilat of this.allData.tarihli_toplamlar.acik) {
          const tarih = new Date(tahsilat.tarih);

          // Bu ayın kaçıncı haftasına denk geliyor kontrolü
          const weekNumber = Math.ceil(tarih.getDate() / 7);

          // Veriyi ilgili haftaya ekle
          seriesData[weekNumber - 1] += Number(tahsilat.toplam_tutar);
          console.log('weeknumber --->', weekNumber);
        }

        this.tahsilatlarGrafikAyData = [
          {
            name:'Toplam Tahsilat',
            data: seriesData,
          },
        ];
      }else if (this.selectedOption === 'Hitam Tahsilat'){
        const seriesData = Array(5).fill(0);

        for (const tahsilat of this.allData.tarihli_toplamlar.kapali) {
          const tarih = new Date(tahsilat.tarih);

          // Bu ayın kaçıncı haftasına denk geliyor kontrolü
          const weekNumber = Math.ceil(tarih.getDate() / 7);

          // Veriyi ilgili haftaya ekle
          seriesData[weekNumber - 1] += Number(tahsilat.toplam_tutar);
          console.log('weeknumber --->', weekNumber);
        }

        this.tahsilatlarGrafikAyData = [
          {
            name:'Toplam Tahsilat',
            data: seriesData,
          },
        ];
      }
    }else if(this.selectedItem === "Bu Hafta"){
      if (this.selectedOption === 'Toplam Tahsilat'){
        const weeklyTotals = Array(7).fill(0);

        for (const tahsilat of this.allData.tarihli_toplamlar.toplam) {
          const tarih = new Date(tahsilat.tarih);

          const dayOfWeek = tarih.getDay();

          weeklyTotals[dayOfWeek - 1] += Number(tahsilat.toplam_tutar);
        }

        this.tahsilatlarGrafikHaftaData = [{
          name:'Toplam Tahsilat',
          data: weeklyTotals,
        }];
      }else if (this.selectedOption==='Tahsilat Oranı'){
        /*const weeklyTotals = Array(7).fill(0);

        for (const tahsilat of this.allData.tarihli_toplamlar.toplam) {
          const tarih = new Date(tahsilat.tarih);

          const dayOfWeek = tarih.getDay();

          weeklyTotals[dayOfWeek - 1] += Number(tahsilat.toplam_tutar);
        }

        this.tahsilatlarGrafikHaftaData = [{
          name:'Toplam Tahsilat',
          data: weeklyTotals,
        }];*/
      }else if(this.selectedOption==='Derdest Kısmi Toplam'){
        const weeklyTotals = Array(7).fill(0);

        for (const tahsilat of this.allData.tarihli_toplamlar.acik) {
          const tarih = new Date(tahsilat.tarih);

          const dayOfWeek = tarih.getDay();

          weeklyTotals[dayOfWeek - 1] += Number(tahsilat.toplam_tutar);
        }

        this.tahsilatlarGrafikHaftaData = [{
          name:'Toplam Tahsilat',
          data: weeklyTotals,
        }];
      }else if(this.selectedOption==='Hitam Tahsilat'){
        const weeklyTotals = Array(7).fill(0);

        for (const tahsilat of this.allData.tarihli_toplamlar.kapali) {
          const tarih = new Date(tahsilat.tarih);

          const dayOfWeek = tarih.getDay();

          weeklyTotals[dayOfWeek - 1] += Number(tahsilat.toplam_tutar);
        }

        this.tahsilatlarGrafikHaftaData = [{
          name:'Toplam Tahsilat',
          data: weeklyTotals,
        }];
      }
    }
  }


  handleDateSelection(dateRange: any) {
    this.selectedItem = null
    const {baslangicTarihi, bitisTarihi} = dateRange;
    this.fetchData("genel_toplam",baslangicTarihi, bitisTarihi);

  }

  handleMuvAndKurumIds(muvId, kurumId) {
    this.selectedMuvId = muvId;
    this.selectedKurumId = kurumId
   this.fetchData("genel_toplam", "2024-01-01", "2024-12-31")
  }

  handleSelectedMuvAndKurumIds(muvId, kurumId) {
    this.selectedMuvId = []
    this.selectedKurumId = []
    this.selectedMuvId = muvId;
    this.selectedKurumId = kurumId


    this.fetchData("genel_toplam", "2024-01-01", "2024-12-31");
  }

  mounted() {
    this.selectedOption='Toplam Tahsilat'
    this.selectedItem = "Bu Yıl";
  }


  async fetchData(typeParam: string, basTar: any, bitTar: any) {

    try {
      let startDate = basTar || "";
      let endDate = bitTar || "";


      if (this.selectedItem === "Bu Ay") {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1;
        const currentYear = currentDate.getFullYear();

        const lastDayOfMonth = new Date(currentYear, currentMonth, 0).getDate();

        startDate = `${currentYear}-${currentMonth.toString().padStart(2, '0')}-01`;
        endDate = `${currentYear}-${currentMonth.toString().padStart(2, '0')}-${lastDayOfMonth}`;

      } else if (this.selectedItem === "Bu Yıl") {
        startDate = "2024-01-01";
        endDate = "2024-12-31";


      } else if (this.selectedItem === "Bu Hafta") {
        const currentDate = new Date();
        const dayOfWeek = currentDate.getDay();
        const diff = currentDate.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
        const startOfWeek = new Date(currentDate.setDate(diff));
        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 6);

        startDate = startOfWeek.toISOString().split('T')[0];
        endDate = endOfWeek.toISOString().split('T')[0];
      }
      const response: any = await this.$http.post('/api/v1/tahsilatlar-raporlama/', {
        "basTar": startDate,
        "bitTar": endDate,
        "muvekkil_id": this.selectedMuvId,
        "kurum_id": this.selectedKurumId,
        "Type": typeParam
      });

      this.baslangicTarihi = startDate
      this.bitisTarihi = endDate
      this.allData =(response)

          if (this.selectedOption === 'Toplam Tahsilat') {
          const seriesData = Array(12).fill(0);

          for (const tahsilat of response.tarihli_toplamlar.toplam) {
            const monthIndex = new Date(tahsilat.tarih).getMonth();
            seriesData[monthIndex] += Number(tahsilat.toplam_tutar);
          }

          this.tahsilatlarGrafikData = [
            {
              name: 'Toplam Tahsilat',
              data: seriesData,
            },
          ]
          }else if(this.selectedOption === 'Hitam Tahsilat'){
            const seriesData = Array(12).fill(0);

            for (const tahsilat of response.tarihli_toplamlar.kapali) {
              const monthIndex = new Date(tahsilat.tarih).getMonth();
              seriesData[monthIndex] += Number(tahsilat.toplam_tutar);
            }


            this.tahsilatlarGrafikData = [
              {
                name: 'Hitam Tahsilat',
                data: seriesData,
              },
            ]
          }


      if (!this.selectedItem) {
        this.tahsilatlarYil= false
        this.tahsilatlarAy = false
        this.tahsilatlarHafta= false


        const subtextNumberToplam = Number((response.genel_tahsilat_toplami / response.takip_tutari) * 100).toFixed(2);
        const subtextValueToplam = isNaN(subtextNumberToplam) ? `${this.type} 0% Toplam Tahsilat` : `${this.type} ${subtextNumberToplam}% Toplam Tahsilat`;

        const subtextNumberAcilmamis = Number((response.acilmamis_takip_tahsilat_tutar_toplam / response.takip_tutari) * 100).toFixed(2);
        const subtextValueAcilmamis = isNaN(subtextNumberAcilmamis) ? `${this.type} 0% Açılmamış Tahsilat` : `${this.type} ${subtextNumberAcilmamis}% Açılmamış Tahsilat`;

        const subtextNumberHitam = Number((response.hitam_takip_tahsilat_tutar_toplam / response.takip_tutari) * 100).toFixed(2);
        const subtextValueHitam = isNaN(subtextNumberHitam) ? `${this.type} 0% Hitam Tahsilat` : `${this.type} ${subtextNumberHitam}% Hitam Tahsilat`;

        const subtextNumberDerdest = Number((response.derdest_takip_tahsilat_tutar_toplam / response.takip_tutari) * 100).toFixed(2);
        const subtextValueDerdest = isNaN(subtextNumberDerdest) ? `${this.type} 0% Derdest Tahsilat` : `${this.type} ${subtextNumberDerdest}% Derdest Tahsilat`;



        this.cards = [
          {
            id: 1,
            icon: 'mdi-cash-multiple',
            title: 'Genel Toplam',
            number: response.takip_tutari? this.$helper.tutarStrIstatistik(response.takip_tutari) : '0 ₺',
            subtext: '',
            backgroundColor: '#79BEF6',
          },
          {
            id: 2,
            icon: 'mdi-chart-line',
            title: 'Toplam Tahsilat',
            number: response.genel_tahsilat_toplami? this.$helper.tutarStrIstatistik(response.genel_tahsilat_toplami): '0 ₺',
            subtext: subtextValueToplam,
            backgroundColor: '#fff',
          },
          {
            id: 3,
            icon: 'mdi-cash-multiple',
            title: 'Açılmamış  Tahsilat',
            number: response.acilmamis_takip_tahsilat_tutar_toplam?this.$helper.tutarStrIstatistik(response.acilmamis_takip_tahsilat_tutar_toplam): '0 ₺',
            subtext: subtextValueAcilmamis,
            backgroundColor: '#fff',
          },
          {
            id: 4,
            icon: 'mdi-cash-multiple',
            title: 'Derdest Tahsilat',
            number: response.derdest_takip_tahsilat_tutar_toplam ? this.$helper.tutarStrIstatistik(response.derdest_takip_tahsilat_tutar_toplam) : '0 ₺',
            subtext: subtextValueDerdest,
            backgroundColor: '#fff',
          },
          {
            id: 5,
            icon: 'mdi-cash-multiple',
            title: 'Hitam Tahsilat',
            number: response.hitam_takip_tahsilat_tutar_toplam ? this.$helper.tutarStrIstatistik(response.hitam_takip_tahsilat_tutar_toplam) : '0 ₺',
            subtext: subtextValueHitam,
            backgroundColor: '#fff',
          },
        ];
      }

      if (this.selectedItem){
        const subtextNumberToplam = Number((response.genel_tahsilat_toplami / response.takip_tutari) * 100).toFixed(2);
        const subtextValueToplam = isNaN(subtextNumberToplam) ? `${this.type} 0% Toplam Tahsilat` : `${this.type} ${subtextNumberToplam}% Toplam Tahsilat`;

        const subtextNumberAcilmamis = Number((response.acilmamis_takip_tahsilat_tutar_toplam / response.takip_tutari) * 100).toFixed(2);
        const subtextValueAcilmamis = isNaN(subtextNumberAcilmamis) ? `${this.type} 0% Açılmamış Tahsilat` : `${this.type} ${subtextNumberAcilmamis}% Açılmamış Tahsilat`;

        const subtextNumberHitam = Number((response.hitam_takip_tahsilat_tutar_toplam / response.takip_tutari) * 100).toFixed(2);
        const subtextValueHitam = isNaN(subtextNumberHitam) ? `${this.type} 0% Hitam Tahsilat` : `${this.type} ${subtextNumberHitam}% Hitam Tahsilat`;

        const subtextNumberDerdest = Number((response.derdest_takip_tahsilat_tutar_toplam / response.takip_tutari) * 100).toFixed(2);
        const subtextValueDerdest = isNaN(subtextNumberDerdest) ? `${this.type} 0% Derdest Tahsilat` : `${this.type} ${subtextNumberDerdest}% Derdest Tahsilat`;



        this.cards = [
          {
            id: 1,
            icon: 'mdi-cash-multiple',
            title: 'Genel Toplam',
            number: response.takip_tutari? this.$helper.tutarStrIstatistik(response.takip_tutari) : '0 ₺',
            subtext: '',
            backgroundColor: '#79BEF6',
          },
          {
            id: 2,
            icon: 'mdi-chart-line',
            title: 'Toplam Tahsilat',
            number: response.genel_tahsilat_toplami? this.$helper.tutarStrIstatistik(response.genel_tahsilat_toplami): '0 ₺',
            subtext: subtextValueToplam,
            backgroundColor: '#fff',
          },
          {
            id: 3,
            icon: 'mdi-cash-multiple',
            title: 'Açılmamış  Tahsilat',
            number: response.acilmamis_takip_tahsilat_tutar_toplam?this.$helper.tutarStrIstatistik(response.acilmamis_takip_tahsilat_tutar_toplam): '0 ₺',
            subtext: subtextValueAcilmamis,
            backgroundColor: '#fff',
          },
          {
            id: 4,
            icon: 'mdi-cash-multiple',
            title: 'Derdest Tahsilat',
            number: response.derdest_takip_tahsilat_tutar_toplam ? this.$helper.tutarStrIstatistik(response.derdest_takip_tahsilat_tutar_toplam) : '0 ₺',
            subtext: subtextValueDerdest,
            backgroundColor: '#fff',
          },
          {
            id: 5,
            icon: 'mdi-cash-multiple',
            title: 'Hitam Tahsilat',
            number: response.hitam_takip_tahsilat_tutar_toplam ? this.$helper.tutarStrIstatistik(response.hitam_takip_tahsilat_tutar_toplam) : '0 ₺',
            subtext: subtextValueHitam,
            backgroundColor: '#fff',
          },
        ];
      }


    } catch (error) {
      console.error('hata:', error);
    }
  }



  selectItem(item) {
    if (item === this.selectedItem) {
      // If the selected item is the same as the current selection, do nothing
      return;
    }

    this.selectedItem = item;

    if (item === "Bu Yıl") {
      this.tahsilatlarYil = true;
      this.tahsilatlarAy = false;
      this.tahsilatlarHafta = false;
      this.fetchData("genel_toplam");


    } else if (item === "Bu Ay") {
      this.tahsilatlarYil = false;
      this.tahsilatlarAy = true;
      this.tahsilatlarHafta = false;
      this.fetchData("genel_toplam");
      this.handleSelectedOption('Toplam Tahsilat')
    } else if (item === "Bu Hafta") {
      this.tahsilatlarYil = false;
      this.tahsilatlarAy = false;
      this.tahsilatlarHafta = true;
      this.fetchData("genel_toplam");
      this.handleSelectedOption('Toplam Tahsilat')

    }
  }

  selectItemOran(item) {
    this.selectedItemTahsilatOrani = item;


    if (item === 'Asıl Alacak') {
      this.type = 'Asıl Alacağa oranla'
      this.fetchData("asil_alacak").then(() => {
        this.cards.find((card) => card.id === 1).title = 'Asıl Alacak';
      });
    } else if (item === 'Takip Toplamı') {
      this.type = 'Takip Toplamına oranla'
      this.fetchData("takip_toplami").then(() => {
        this.cards.find((card) => card.id === 1).title = 'Takip Toplamı';

      });


    } else if (item === 'Genel Toplam') {
      this.type = 'Genel Toplama oranla'
      this.fetchData("genel_toplam").then(() => {
        this.cards.find((card) => card.id === 1).title = 'Genel Toplam';
      });
    }
  }

};
