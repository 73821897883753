<template>
  <v-row >
      <v-col cols="12" class="sayfa-info">
        <div class="pt-5 ana-baslik">İstatistikler</div>
        <div class="pt-2 alt-baslik">İstatistikler, tahsilat performansını ve henüz tahsil edilmeyen miktarları göstererek bilgi sunar. </div>
      </v-col>
      <v-col>
        <v-tabs  background-color="transparent"  class="slider-basliklar font-weight-600" v-model="tabs" >
          <v-tabs-slider color="#5FB3F6" ></v-tabs-slider>
          <v-tab

              @click="handleButtonClick(button)"
              class="button-style text-center"
              :class="{ 'active-button': isButtonActive(button) }"
              v-for="button in buttons"
              :key="button.label"
          >
            {{ button.label }}
            <v-col v-if="button.label==='Karşılaştırmalı Tahsilatlar'">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon size="17" color="#5D7285" dark v-bind="attrs" v-on="on">
                    mdi-information
                  </v-icon>
                </template>
                <span>Seçilen iki yılın karşılaştırmasıdır, yıllar arası karşılaştırma yapılamaz.(Örn: 2022 ile 2024 tahsilatları)
                </span>
              </v-tooltip>
            </v-col>
          </v-tab>


        </v-tabs>
      </v-col>
  </v-row>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";

@Component({})
  export default class  UstBasliklar extends Vue {
  tabs = 0;

  buttons = [
    {
      label: "Genel İstatistik",
      route: "/ofispro/genel-istatistikler",
    },
    {
      label: "Tahsilatlar",
     route: "/ofispro/tahsilatlar"

    },
    {
      label: "Karşılaştırmalı Tahsilatlar",
      route: "/ofispro/karsilastirmali-tahsilatlar",
    },
  ];


  mounted() {
    this.setActiveTab();

  }

  setActiveTab() {
    const currentRoute = this.$route.path;

    for (let i = 0; i < this.buttons.length; i++) {
      const button = this.buttons[i];

      if (button.route && currentRoute.startsWith(button.route)) {
        this.tabs = i;
        return;
      }
    }

  }

  isButtonActive(button) {
    const currentRoute = this.$route.path;

    if (button.route && currentRoute === button.route) {
      return true;
    }


    return false;
  }

  handleButtonClick(button) {
    if (button.route) {
      this.$router.push(button.route);
    }
  }

}
</script>



<style scoped lang="scss">

@media (max-width: 1000px) {
  .sayfa-info {
    margin-left: 10% !important;
  }
  .slider-basliklar{
    margin-left: -3% !important;
  }

}

.ana-baslik{
  font-size: 28px;
  font-weight: bold;
  color: #534D5A;
}

.alt-baslik{
  font-size: 14px;
  color: #C4C4C4;
}

.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active),
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-icon,
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-btn,
.theme--light.v-tabs > .v-tabs-bar .v-tab--disabled {
  color: rgba(196, 196, 196, 1); /* Renk kodunu değiştirebilirsiniz */
}


.sayfa-info{
  margin-left: 2.3% ;
}

.slider-basliklar{
  font-size: 18px;
  margin-left: 1.9%;
}

.active-button {
  color: #5FB3F6 !important;
}
.button-style {
  text-transform: capitalize;
  color: #C6C6C6;
  font-size: 16px;
  margin-left: -0.5%;
  margin-right: 10px;
  font-weight: 600;
}

</style>
