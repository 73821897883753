
import {Vue, Component, Prop, Watch, Mixins} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({

})
export default class TahsilatlarAyChart extends Mixins(SimpleInputMixin){
  @Prop()series!:Array<any>
  @Prop() selectedMuvId: number | null | undefined;
  @Prop() selectedKurumId: number | null | undefined;

  tahsilatOraniTitleChart = ["Toplam Tahsilat", "Tahsilat Oranı", "Derdest Kısmi Toplam", "Hitam Tahsilat"]

  options = {
    series: this.series,
    chart: {
      type: 'bar',
    },
    colors: ["#5FB3F6", "#BFC9D2"],
    plotOptions: {
      bar: {
        borderRadius: 4,
        columnWidth: '10%',
        distributed: true,
      }
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: false
    },
    xaxis: {
      categories: [
        '1.Hafta',
        '2.Hafta',
        '3.Hafta',
        '4.Hafta',
        '5.Hafta',
      ],
      labels: {
        style: {
          show: true,
          fontSize: '11px',
          color:'#A4A4A4',
        }
      }
    }
  };
}
