
import Dates from "@/components/inputs/Dates.vue";
import {Component, Mixins, Vue, Watch} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
@Component({
  components: {
    Dates,
  }
})

export default class OzelAralikDatePicker extends Mixins(ObjectInputMixin){

  baslangicTarihi : Date | null = null;
  bitisTarihi : Date | null = null;
  type: string = '';
  isMenuOpen: boolean = false;
  rangeDate:any = ['',''];

  mounted(){
    this.dateTypeDay()
  }
  get dogruTarih() {
    return this.baslangicTarihi && this.bitisTarihi && this.baslangicTarihi > this.bitisTarihi;
  }

  closeMenu() {
    this.isMenuOpen = false;
  }

  buttonUygula(){
    if (this.baslangicTarihi && this.bitisTarihi && this.baslangicTarihi > this.bitisTarihi) {
      alert('Başlangıç Tarihi, Bitiş Tarihinden büyük olamaz.');
    } else {
      this.$emit('dateSelected', { baslangicTarihi: this.baslangicTarihi, bitisTarihi: this.bitisTarihi });
      this.closeMenu();
    }
  }
  @Watch('baslangicTarihi')
  @Watch('bitisTarihi')
  onChangeBitisTarihi() {
    if (this.dogruTarih) {
      this.bitisTarihi = null;
    }
    this.updateRangeDate;
  }

  dateTypeDay() {
    this.type = 'date';
  }

  dateTypeMonth() {
    this.type = 'month';
    this.baslangicTarihi = null;
    this.bitisTarihi = null;
  }

  dateTypeYear() {
    this.type = 'year';
    this.baslangicTarihi = null;
    this.bitisTarihi = null;
  }

  dateTemizle(){
    this.baslangicTarihi = null
    this.bitisTarihi = null
  }

  get updateRangeDate() {
    return this.rangeDate = [this.baslangicTarihi, this.bitisTarihi];
  }

}
