
import Dates from "@/components/inputs/Dates.vue";
import {Component, Mixins, Vue, Watch} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import OzelAralikDatePicker from "@/components/infos/raporlama-ofispro/helper-comps/OzelAralikDatePicker.vue";
import {MuvekkilEntity} from "@/entity/MuvekkilEntity";
@Component({
  components: {
    OzelAralikDatePicker,
    Dates,
  }
})

export default class MuvekkilSecim extends Mixins(ObjectInputMixin){

  selectedItem = null
  isMenuOpen: boolean = false;
  items : Array<MuvekkilEntity> = []
  checkedItems: { [key: string]: boolean } = {};
  search: string = '';

  allData:any = []
  selectAll: boolean = true;
  muvIdArray:Array<MuvekkilEntity> = []
  kurumIdArray:Array<MuvekkilEntity> = []
  selectedMuvIds: Array<MuvekkilEntity> = [];
  selectedKurumIds: Array<MuvekkilEntity> = [];
  get filteredItems() {
    return this.items.filter((item:any) =>
      item.toLowerCase().includes(this.search.toLowerCase())
    );
  }

  mounted(){
    this.fetchData()
  }
  @Watch('selectAll')
  onAllCheckedChanged(value: boolean) {
    for (const key in this.checkedItems) {
      if (Object.prototype.hasOwnProperty.call(this.checkedItems, key)) {
        this.checkedItems[key] = value;
      }
    }
  }

  muvekkilButton(){
    this.selectedMuvIds = [];
    this.selectedKurumIds = [];
    for (const [itemName, isChecked] of Object.entries(this.checkedItems)) {
      if (isChecked) {
        // Check if the item is a MuvekkilEntity with id
        const muvEntity = this.allData[0].find((item: any) =>
          (item.ad + ' ' + item.soyad === itemName) || (item.sirket_adi === itemName)
        );

        if (muvEntity) {
          if (muvEntity.id && !muvEntity.kurum_id) {
            this.selectedMuvIds.push(muvEntity.id);
            this.selectedKurumIds.push()
          }
          if (muvEntity.kurum_id && !muvEntity.id) {
            this.selectedKurumIds.push(muvEntity.kurum_id);
            this.selectedMuvIds.push()
          }
        }
      }
    }
    this.isMenuOpen =false

    // Emit the selected IDs
    this.$emit('selectedIds', this.selectedMuvIds,this.selectedKurumIds);
  }


  async fetchData() {
    const response: any = await this.$http.get('/api/v1/tahsilat-muvekkil-list/',)

    this.allData.push(response)

    this.items=response.map((muvekiller:any) =>{
      if (muvekiller.id){
        this.$set(this.checkedItems, muvekiller.ad + ' ' + muvekiller.soyad, true);
        return muvekiller.ad + ' ' + muvekiller.soyad
      }else if (muvekiller.kurum_id){
        this.$set(this.checkedItems, muvekiller.sirket_adi, true);
        return muvekiller.sirket_adi
      }

    })

    response.forEach((item:any)=>{
      if (item.id){
        this.muvIdArray.push(item.id)
      }else{
        this.kurumIdArray.push(item.kurum_id)
      }
    })
    this.$emit('MuvAndKurumName',this.items)
    this.$emit('Ids',this.muvIdArray, this.kurumIdArray)
  }

  selectItem(item: MuvekkilEntity) {
    if (item === this.selectedItem) {
      // If the selected item is the same as the current selection, do nothing
      return;
    }

    this.selectedItem = item;

  }

}
